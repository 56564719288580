import styled, { css } from 'styled-components';
import { theme } from '@theme';

import WrPointer from '@commons/wr-pointer';
import WrButton from '@commons/wr-button';
import WrImage from '@commons/wr-image';
import ModuleTitle from '@commons/module-title';
import WysiwygContent from '@commons/wysiwyg-content';

import { StyledModuleContainer, StyledContainer } from '@styles/global-components/_containers';

const content_spacing = '35px';
const md_content_spacing = '40px';

const inner_max_width = '700px';

const contentSpacer = css`

    ${props => !props.isBlogPage ? css`
        margin-bottom: 35px;

        ${theme.mediaQuerys.mdUp} {
            margin-bottom: 40px;
        }
    `:css`
        margin-bottom: 25px;

        ${theme.mediaQuerys.mdUp} {
            margin-bottom: 30px;
        }
    `}
`

const blogContentSpacer = css`

`

// export const StyledSingleVideoCta = styled(StyledModuleContainer)`


// `

export const ThumbnailAndVideoContainer = styled.div`
    position: relative;
    z-index: 0;
    margin: 0 auto;

    ${props => !props.isBlogPage ? css`
        max-width: 700px;
    `:css`
        max-width: 575px;
    `}
`

// client didn't want this to be a heading tag
export const StyledTitle = styled(ModuleTitle)`
    font-weight: 800;
    margin-top: 0;
    margin-bottom: 30px;
    text-align: center;

    ${theme.mediaQuerys.mdUp} {
        margin-bottom: 35px;
    }

    ${props => !props.isBlogPage ? css`
        margin-bottom: 25px;

        ${theme.mediaQuerys.mdUp} {
            margin-bottom: 30px;
        }
    `:css`
        margin-bottom: 20px;

        ${theme.mediaQuerys.mdUp} {
            margin-bottom: 25px;
        }
    `}
`

export const StyledSubTitle = styled(WysiwygContent)`
    /* font-size: 1.6rem;
    line-height: 21px; */
    text-align: center;

    ${theme.mediaQuerys.smUp} {
        /* font-size: 1.8rem; */
        /* text-align: center; */
    }

    ${props => !props.isBlogPage ? css`
        margin-bottom: 33px;

        ${theme.mediaQuerys.mdUp} {
            margin-bottom: 38px;
        }
    `:css`
        margin-bottom: 23px;

        ${theme.mediaQuerys.mdUp} {
            margin-bottom: 28px;
        }
    `}

    /* p {
        margin: 0;
    } */
`

export const StyledVideoThumbnail = styled(WrImage)`
    position: relative;
    opacity: 1;
    visibility: visible;
    width: 100%;
    transition: .3s opacity, .3s visibility;
    transition-delay: .1s;
    z-index: 1;
    cursor: pointer;
    ${contentSpacer}

    ${props => props.isVideoOpen && css`
        opacity: 0;
        visibility: hidden;
        z-index: -1;
    `}
`

export const StlyedVideoContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .video-responsive {
        overflow:hidden;
        padding-bottom:56.25%;
        position:relative;
        height:0;
    }

    .video-responsive iframe{
        left:0;
        top:0;
        height:100%;
        width:100%;
        position:absolute;
    }
`

export const StyledButtonContainer = styled.div`
    max-width: ${inner_max_width};
    margin: 0 auto;
    position: relative;
`

export const StyledButton = styled(WrButton)`

    ${theme.mediaQuerys.smUp} {
        width: ${props => props.$isPointerEnabled ? '75%' : ''};
    }
`

export const StyledPointer = styled(WrPointer)`
    width: 15%;
    display: none;
    position: absolute;
    top: -60px;
    right: 25px;
    transform: rotate(35deg);

    ${theme.mediaQuerys.smUp} {
       display: block;
    }

    ${theme.mediaQuerys.mdUp} {
        top: -70px;
    }
`