import React, { useState, useEffect } from 'react';
import { StyledButtonContainer, ThumbnailAndVideoContainer, StlyedVideoContainer, StyledSubTitle, StyledTitle, StyledVideoThumbnail, StyledPointer, StyledButton } from './styles';

import { StyledModuleContainer, StyledContainer } from '@styles/global-components/_containers';

export default function SingleVideoCta({
    module, 
    isBlogPage
}) {

    const [isVideoOpen, setisVideoOpen] = useState(false);
    const openVideo = () => setisVideoOpen(true);

     if(module && module.videoId) return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            moduleSpacing={'md'}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            data-name="single-video"
        >
            <StyledContainer
                type={isBlogPage ? 'inner' : 'standard'}
            >  
                {module.title && 
                    <StyledTitle
                        isBlogPage={isBlogPage}
                        renderAs={'p'}
                        titleFontOptions={!isBlogPage ?
                            { mobile: 'sm', desktop: 'sm' }:
                            { mobile: 'sm', desktop: 'xs' }
                        }
                    >
                        {module.title}
                    </StyledTitle>
                }
                {module.text && 
                    <StyledSubTitle 
                        isBlogPage={isBlogPage}
                        bodyFontOptions={!isBlogPage ?
                            { mobile: 'md', desktop: 'sm' }:
                            { mobile: 'sm', desktop: 'sm' }
                        }
                    >
                        {module.text}
                    </StyledSubTitle>
                }
                <ThumbnailAndVideoContainer isBlogPage={isBlogPage}>
                    {module.videoThumbnail && 
                        <div onClick={openVideo}>
                            <StyledVideoThumbnail 
                                alt={module.videoThumbnail.altText}
                                imageFile={module.videoThumbnail.imageFile}
                                isVideoOpen={isVideoOpen}
                            /> 
                        </div>
                    }
                    {module.videoId && 
                        <Video
                            videoId={module.videoId}
                            videoType={module.videoType}
                            isOpen={isVideoOpen}
                        />
                    }
                </ThumbnailAndVideoContainer>
                <StyledButtonContainer isBlogPage={isBlogPage}>
                    {module.buttonLink && 
                        <StyledButton 
                            href={module.buttonLink.url} 
                            buttonOptions={module.buttonOptions}
                            fontSize={'sm'} 
                            className="video-btn"
                            $isPointerEnabled={module.enablePointer}
                        >
                            {module.buttonLink.title}
                        </StyledButton>
                    }
                    {module.enablePointer &&
                        <StyledPointer 
                            color={module.pointerColor}
                        />
                     } 
                </StyledButtonContainer>
            </StyledContainer>
        </StyledModuleContainer>
    )
    return null;
}

// TODO -- need to add dynamic video title here??
const Video = ({ videoId, videoType, videoUrl = '', isOpen }) => {

    const [activeVideoId, setActiveVideoId] = useState(null)

    useEffect(() => {
        if(isOpen && activeVideoId !== videoId) setActiveVideoId(videoId);
    }, [isOpen])

    return (
        <StlyedVideoContainer className="video">
            <div className="video-responsive">
                <VideoSelector 
                    videoId={activeVideoId}
                    videoType={videoType}
                />
            </div>
        </StlyedVideoContainer>
      
    )
}

const VideoSelector = ({ videoType, videoId }) => {
    if (videoType === "youtube") return (<YouTube videoId={videoId} />);
    return null;
};


const YouTube = ({ videoId }) => {
    return (
        <iframe
            width="600"
            height="350"
            src={videoId ? `https://www.youtube.com/embed/${videoId}?autoplay=1` : 'about:blank'}
            frameBorder="0"
            title={'Writing Revolt youtube video'}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    );
};