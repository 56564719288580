import React from "react";

import SingleVideoCta from "./single-video-cta";

const Start = ({ module = { display: '' }, isBlogPage }) => {
    const { video } = module;
    const { display } = video;

    switch(display) {
        case "single-video-cta":
            return <SingleVideoCta module={video} isBlogPage={isBlogPage} />;
        default:
            return null;
    }
};

export default Start;